/**
 * Site footer
 */
.footer-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 24px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: visible;
  background-color: #000;
  color: #fff;
}

.footer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block;
  width: 100%;
  max-width: 900px;
  padding-top: 72px;

  .site-title {
    font-size: 1.75rem;

    @include media-query($on-phone) {
      font-size: 1.5rem;
    }
  }

  a {
    color: #b4b5be;
    text-decoration: none;
  }

  h5 {
    color: #fff;
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.2em;
    font-weight: 700;
    line-height: 20px;
    margin-top: 10px;
  }

  .emoji {
    display: none;
  }
  .footer-about {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    @include media-query($on-palm) {
      display: block;
    }
  }

  .footer-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-right: auto;
    padding: 0 15px 15px 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-column:last-child {
    margin-right: 0px;
  }

  .footer-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 80px;
    padding-top: 32px;
    padding-bottom: 32px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid hsla(0, 0%, 100%, 0.2);
    font-size: 12px;
    line-height: 16px;
    @include media-query($on-phone) {
      display: block;
    }
  }

  .subtitle {
    font-size: 20px;
    line-height: 2em;

    @include media-query($on-phone) {
      font-size: 14px;
    }
  }
  visibility: visible;
  opacity: 1;
}
