.test_below_fold {
  color: #333;
}

.ataglance {
  font-family: "Source Sans Pro", sans-serif;
  background: rgba(250, 250, 250, 1);
  margin: 30px 0 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 20px 20px;
  h5 {
    margin-top: 0;
    text-align: center;
    font-size: 1.3em;
  }
  > .prosandcons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(Min(320px, 100%), 1fr));
    grid-gap: 1rem;
    h6 {
      margin-top: 0;
      font-size: 1.3em;
      font-weight: 800;
      font-family: $base-font-family;
    }
    > div {
      font-size: 1rem;
      line-height: 1.3;
      padding: 1rem 0.75rem;
    }
    li,
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 1em;
      font-family: $base-font-family;
    }
    li {
      padding: 0.5em 0 0;
    }
  }
}

.post-more {
  font-size: ($copy-font-size * 1.25);

  @include media-query($on-laptop) {
    font-size: ($copy-font-size * 1.1);
  }
  @include media-query($on-phone) {
    font-size: $copy-font-size;
  }

  a {
    color: $text-color;
    transition: all 0.1s ease-in-out;

    &:visited {
      color: darken($brand-color, 0%);
      color: $text-color;
    }

    &:hover {
      color: $brand-color;
      box-shadow: none;
      text-decoration: none;
      transition: all 0.1s ease-in-out;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.1em;
  }

  h1 {
    margin-top: 2em;
    font-size: 3em;
  }

  h2 {
    margin-top: 2em;
    font-size: 2em;
  }

  h3 {
    font-size: 1.75em;
    margin-top: 1.5em;
  }

  h4 {
    font-size: 1.5em;
    margin-top: 1.25em;
  }

  h5 {
    font-size: 1.25em;
    margin-top: 1em;
  }

  h6 {
    font-size: 1.1em;
    margin-top: 1em;
  }

  p,
  ul,
  li {
    font-family: $copy-font-family;
    font-size: 1em;
    line-height: 1.75em;
    -webkit-font-smoothing: antialiased;
  }

  p {
    margin-bottom: 1em;
  }
}

.latest-articles,
.read-more-cards {
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include media-query(780px) {
    grid-template-columns: 1fr;
  }

  .article,
  .card {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.3)
      ),
      grey no-repeat center center;
    padding: 15px;
    margin: 45px 0;
    min-height: 200px;
    background-size: cover;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
      transition: all 0.2s ease-in-out;
    }

    a,
    a:visited {
      color: white;
      box-shadow: none;
    }

    h2 {
      color: $header-color;
      font-family: $logo-font-family, Arial;
      font-weight: 700;
      line-height: 1.4;
      margin: 0.8em 0;

      @include media-query($on-phone) {
        font-size: 22px;
      }
    }

    .article-title {
      margin: 0 30px;
      span {
        background: #fff;
        box-shadow: 0.4em 0 0 #fff, -0.4em 0 0 #fff;
      }
    }

    .article-meta {
      font-size: $small-font-size;
      color: white;
      bottom: 13px;
      font-family: Arial;
      line-height: 14px;
      position: absolute;
      right: 20px;
      text-align: right;
      width: calc(100% - 50px);

      .article-meta-tags {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: flex-end;
        .tag {
          border-radius: 10px;
          text-align: right;
          padding: 2px 4px;
          margin: 3px 0 3px 5px;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .card {
    padding: 15px;
    margin: 0;

    h2 {
      font-size: 26px;
    }
  }

  a,
  a:visited {
    color: white;
    box-shadow: none;
  }
}

.read-more {
  a {
    text-decoration: none;
    color: inherit;
  }

  margin-bottom: 30px;

  .read-more-cards {
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);

    @include media-query($content-width) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-query($on-palm) {
      grid-template-columns: 1fr;
    }

    .article,
    .card {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
        transition: all 0.2s ease-in-out;
      }
      background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.3)
        ),
        grey no-repeat center center;
      padding: 15px;
      margin: 0;
      min-height: 105px;
      // max-width: 50%;

      h2 {
        color: $header-color;
        font-family: $logo-font-family, Arial;
        font-weight: 700;
        line-height: 1.3;
        margin: 0.3em 0;
        font-size: 18px;

        @include media-query($on-palm) {
          font-size: 22px;
        }

        @include media-query($on-phone) {
          font-size: 18px;
        }
      }

      .article-title {
        margin: 0 10px;
        span {
          background: #fff;
          box-shadow: 0.4em 0 0 #fff, -0.4em 0 0 #fff;
        }
      }

      .article-meta {
        display: none;
      }
    }
  }
}

.hidden {
  display: none;
}

.sharing-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font: 16px Roboto, sans-serif;
  margin-bottom: 15px;

  a {
    text-decoration: none;
    color: inherit;
  }

  .c-icon {
    width: 36px;
    height: 36px;
    fill: currentColor;
    transition: 0.2s;
  }

  .c-link--facebook:hover {
    color: #3b5998;
  }
  .c-link--twitter:hover {
    color: #55acee;
  }
  .c-link--google:hover {
    color: #dd4b39;
  }
  .c-link--pinterest:hover {
    color: #bd081c;
  }
  .c-link--instagram:hover {
    color: #833ab4;
  }
  .c-link--linkedin:hover {
    color: #0077b5;
  }
  .c-link--reddit:hover {
    color: #ff4500;
  }
  .c-link--snapchat:hover {
    color: #fbe00f;
  }
  .c-link--facebook:hover::after {
    background: #3b5998;
  }
  .c-link--twitter:hover::after {
    background: #55acee;
  }
  .c-link--google:hover::after {
    background: #dd4b39;
  }
  .c-link--pinterest:hover::after {
    background: #bd081c;
  }
  .c-link--instagram:hover::after {
    background: #833ab4;
  }
  .c-link--linkedin:hover::after {
    background: #0077b5;
  }
  .c-link--reddit:hover::after {
    background: #ff4500;
  }
  .c-link--snapchat:hover::after {
    background: #fbe00f;
  }

  .c-link {
    position: relative;
    color: #bbb;
  }

  .social-arrow {
    color: #bbb;
    margin: 0 20px;
    @include media-query($on-phone) {
      display: none;
    }
  }

  .c-link:not(:last-of-type) {
    margin-right: 15px;
  }

  .c-tooltip::before,
  .c-tooltip::after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -10px);
    opacity: 0;
    transition: 0.2s;
  }

  .c-tooltip::before {
    content: "";
    bottom: calc(100% + 2px);
    border: solid 5px transparent;
    border-top-color: currentColor;
  }

  .c-tooltip::after {
    content: attr(aria-label);
    bottom: calc(100% + 12px);
    padding: 0.61em 0.93em;
    font-size: 0.875rem;
    color: white;
    border-radius: 3px;
  }

  .c-tooltip:hover::before,
  .c-tooltip:hover::after {
    opacity: 1;
    transform: translate(-50%);
  }
}

.display-fund {
  p {
    font-size: 0.8em;
  }
}

.scroll-table {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #eeeeee;
  width: 100%;
  border-spacing: 0;

  td,
  th {
    padding: 10px 10px;
  }

  td em {
    font-size: 14px;
  }

  tr:nth-child(even) {
    background: #e0e0e0;
  }

  tr td:first-child,
  thead th {
    font-size: 14px;
    border: 1px solid #fff;
    font-weight: bold;
    color: #ffffff;
    background: #1d8642;

    @include media-query($on-phone) {
      font-size: 10px;
    }
  }

  tr td:first-child,
  thead th:first-child {
    left: 0;
    z-index: 1;
    position: sticky;
  }

  @include media-query($on-laptop) {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.two-col-table {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #eeeeee;
  width: 100%;
  border-spacing: 0;

  td,
  th {
    border: 1px solid #fff;
    padding: 10px 10px;
  }

  td em {
    font-size: 14px;

    @include media-query($on-phone) {
      font-size: 12px;
    }
  }

  td:nth-child(1) {
    width: 30%;
    font-size: 16px;
    min-width: 90px;

    @include media-query($on-phone) {
      font-size: 14px;
    }
  }

  td:nth-child(2) {
    width: 70%;
  }

  thead th {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: #1d8642;

    @include media-query($on-phone) {
      font-size: 14px;
    }
  }

  table.features tr:nth-child(even) {
    background: #e0e0e0;
  }
}

td .table-button {
  display: inline-table;
  background-color: $brand-color;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: #fff;
  font-family: $base-font-family;
  font-weight: bold;
  font-size: 0.6em;
  padding: 2px 4px;
  text-decoration: none;
  text-align: center;
  margin: 10px;
  border: 2px solid $brand-color;

  &:hover {
    background-color: #2e874c;
    border: 2px solid #2e874c;
  }
  &:hover,
  &:visited {
    color: #fff;
  }
}
