.test_shared_css {
  color: #333;
}

// Our variables
$base-font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
  sans-serif;
$logo-font-family: "Source Sans Pro", sans-serif;
$logo-font-weight: 700;
$h-font-family: "Patua One", serif;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$copy-font-family: "Merriweather", Georgia, serif;
$copy-font-size: 16px;

$spacing-unit: 30px;

$text-color: #111;
$background-color: #fff;
// $brand-color: #34a857; // darker, more serious than spotify, sea green
// $brand-color: #17a817; // original
$brand-color: #1d8642; // close to spotify, medium sea green

$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);

$header-color: #000;
$header-menu-color: #ccc;
$logo-color: #fff;
$nav-color: #fff;
$nav-bg-color: rgba(0, 0, 0, 0.5);

// Width of the content area
$content-width: 900px;

$on-ipod: 380px;
$on-phone: 550px;
$on-palm: 700px;
$on-laptop: 800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

.test_main {
  color: #333;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "below-fold", "footer", "syntax-highlighting";

// Fix preloading temp styles
.site-cover .wrapper,
.site-header .wrapper {
  visibility: visible;
  opacity: 1;
}
